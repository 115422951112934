import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
const queryString = require("query-string");

function App() {
  const [communityPrivate, setCommunityPrivate] = useState(false);
  const [name, setName] = useState("");
  const [domain, setDomain] = useState(null);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const params = queryString.parse(window.location.search);
  let invite = "";
  if ("invite" in params) {
    invite = params["invite"];
  }

  const formatDomain = s => {
    return s.replace(/\W/g, "").toLowerCase();
  };

  const validate = () => {
    if (name.length < 5) {
      setError("Community name must be at least 5 characters");
      return true;
    }

    if (name.length > 50) {
      setError("Community name must be no more than 50 characters");
      return true;
    }

    const finalDomain =
      domain !== null ? formatDomain(domain) : formatDomain(name);

    if (finalDomain.length < 5) {
      setError("Domain must be at least 5 characters");
      return true;
    }

    if (finalDomain.length > 50) {
      setError("Domain must be no more tha 50 characters");
      return true;
    }

    if (username.length < 5) {
      setError("Username must be at least 5 characters");
      return true;
    }

    if (username.length > 50) {
      setError("Username canot be more than 50 characters");
      return true;
    }

    if (
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        email
      )
    ) {
      setError("Invalid email address");
      return true;
    }

    if (password !== verifyPassword) {
      setError("Password and Verify Password does not match");
      return true;
    }

    if (password.length < 5) {
      setError("Password must be at least 5 characters");
      return true;
    }

    return false;
  };

  const onSubmit = () => {
    if (validate()) {
      return;
    }
    setLoading(true);
    const finalDomain =
      domain !== null ? formatDomain(domain) : formatDomain(name);

    fetch("https://api.comradery.io/v1/community/create", {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        community_name: name,
        community_domain: finalDomain,
        community_private: communityPrivate,
        account_email: email,
        account_password: password,
        account_username: username,
        registration_code: invite
      })
    })
      .catch(error => {
        console.log("Here");
        console.log(error);
        setLoading(false);
        setError(JSON.parse(error));
      })
      .then(data => {
        if (data.status >= 200 && data.status < 300) {
          setLoading(false);
          data.json().then(data => {
            window.location = data.domain + "?token=" + data.token;
          });
        } else {
          setLoading(false);
          data.text().then(data => setError(data));
        }
      })
      .catch(error => {
        setLoading(false);
        setError(JSON.parse(error));
      });
  };

  return (
    <div
      style={{ height: "max(100vh, 100%)" }}
      className="flex flex-col items-center justify-center w-full bg-gray-200 md:py-10"
    >
      <div className=" md:px-20 py-10 pb-20 rounded-lg ">
        <img src="/logo512.png" width={200} className=" mx-auto mb-4" />
        <div className="my-4 text-2xl text-center text-blue-800 ">
          Welcome to Comradery! Let's set up your community 😊
        </div>
        <div className="border rounded-lg px-5 py-5 mt-4 bg-white shadow-lg">
          <div className="font-bold mb-2">Community Name</div>
          <input
            onChange={e => setName(e.target.value)}
            value={name}
            type="text"
            className="border rounded w-full border-gray-500 p-2 outline-none focus:border-blue-500"
          />

          <div className="font-bold mb-2 mt-4">Domain</div>

          <div className="flex flex-row items-center">
            <input
              onChange={e => setDomain(e.target.value)}
              value={
                domain !== null ? formatDomain(domain) : formatDomain(name)
              }
              type="text"
              className="border text-right rounded w-full border-gray-500 p-2 outline-none focus:border-blue-500"
            />
            <div>.comradery.io</div>
          </div>

          <div className="rounded border-2 text-blue-700 border-blue-700 bg-blue-100 py-2  my-4 px-4">
            Want a custom domain?{" "}
            <a
              href="mailto:hello@comradery.io"
              className="underline hover:text-blue-900"
            >
              Contact us
            </a>{" "}
            for pricing
          </div>

          <div className="font-bold mb-2 mt-6 text-center">I want a:</div>
          <div className="flex flex-row justify-center align-center">
            <div
              onClick={() => setCommunityPrivate(false)}
              className={
                (communityPrivate
                  ? "border-gray-600 text-gray-700 border"
                  : "border-blue-500 text-blue-500 font-bold border-2") +
                " p-5 mr-5 border-2 rounded cursor-pointer bg-gray-200"
              }
            >
              Public Community
            </div>
            <div
              onClick={() => setCommunityPrivate(true)}
              className={
                (communityPrivate
                  ? "border-blue-500 text-blue-500 font-bold border-2"
                  : "border-gray-500 text-gray-700 border") +
                " p-5 ml-5 rounded cursor-pointer bg-gray-200"
              }
            >
              Private Community
            </div>
          </div>

          <div className="text-2xl my-2 mt-10 text-center">
            Your Admin Account
          </div>

          <div className="font-bold mb-2">Email</div>
          <input
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            className="border rounded w-full border-gray-500 p-2 outline-none focus:border-blue-500"
          />

          <div className="font-bold mb-2 mt-6">Username</div>
          <input
            value={username}
            onChange={e => setUsername(e.target.value)}
            type="text"
            className="border rounded w-full border-gray-500 p-2 outline-none focus:border-blue-500"
          />

          <div className="font-bold mb-2 mt-6">Password</div>
          <input
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            className="border rounded w-full border-gray-500 p-2 outline-none focus:border-blue-500"
          />
          <div className="font-bold mb-2 mt-6">Verify Password</div>
          <input
            value={verifyPassword}
            onChange={e => setVerifyPassword(e.target.value)}
            type="password"
            className="border rounded w-full border-gray-500 p-2 outline-none focus:border-blue-500"
          />
          <div>
            {error && (
              <div className="bg-red-600 text-white py-4 my-4 text-center">
                {error}
              </div>
            )}
            <div
              onClick={loading ? null : onSubmit}
              className={
                loading
                  ? "w-40 mx-auto mt-4 flex justify-center items-center font-bold rounded bg-blue-300 px-2 py-4"
                  : "w-40 mx-auto mt-4 flex justify-center items-center hover:bg-blue-800 cursor-pointer font-bold rounded bg-blue-600 text-white px-2 py-4"
              }
            >
              {loading ? "Loading" : "Submit"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
